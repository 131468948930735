import * as Sentry from "@sentry/nextjs";
import {
  type CaptureContext,
  type Severity,
  type SeverityLevel,
  UserFeedback,
} from "@sentry/types";
import { ExclusiveEventHintOrCaptureContext } from "@sentry/core/types/utils/prepareEvent";

class MonitoringService {
  static setUser(user: { id: string; email: string } | null) {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      return;
    }

    Sentry.setUser(
      user
        ? {
            id: user.id,
            email: user.email,
          }
        : null,
    );
  }

  static clearUser() {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      return;
    }
    Sentry.setUser(null);
  }

  static captureException(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exception: any,
    hint?: ExclusiveEventHintOrCaptureContext,
  ): string | undefined {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      // eslint-disable-next-line no-console
      console.error("[ERROR (captured)]", exception);
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Sentry.captureException(exception, hint as any);
  }

  static captureMessage(
    message: string,
    captureContext?: CaptureContext | SeverityLevel | Severity | undefined,
  ): string | undefined {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      // eslint-disable-next-line no-console
      console.error("[MESSAGE (captured)]", message);
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Sentry.captureMessage(message, captureContext as any);
  }

  static captureUserFeedback(userFeedback: UserFeedback) {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      return;
    }
    Sentry.captureUserFeedback(userFeedback);
  }

  static addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === "false") {
      return;
    }
    Sentry.addBreadcrumb(breadcrumb);
  }
}

export default MonitoringService;
